<template>
  <div class="tooltip-container" @mouseover="triggerTooltip" @mouseleave="hideTooltip">
    <div ref="trigger" class="tooltip-trigger">
      <slot />
    </div>
    <transition name="ease">
      <div
        v-if="tooltipVisible"
        v-show="enabled"
        ref="tooltip"
        class="tooltip-text"
        :style="{ ...tooltipPosition, width: tooltipWidth }"
        :class="[tooltipArrowLocation, variant]"
      >
        {{ text }}
        <slot name="tooltip-content" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MTooltip',
  status: 'ready',
  release: '2.1.0',
  props: {
    /**
     * Tooltip text, you can also use the slot.
     */
    text: {
      type: String,
      default: ''
    },
    /**
     * True if the tooltip should be triggered, false if you dont want to show the tooltip on hover.
     */
    enabled: {
      type: Boolean,
      default: true
    },
    /**
     * Sets the tooltip position. `is-dynamic`, `is-top-left`, `is-top-center`, `is-top-right`, `is-center-left`, `is-center-right`, `is-bottom-left`, `is-bottom-center`, `is-bottom-right`
     */
    position: {
      type: String,
      default: 'is-dynamic',
      validator: (val) => {
        return [
          'is-dynamic',
          'is-top-left',
          'is-top-center',
          'is-top-right',
          'is-center-left',
          'is-center-right',
          'is-bottom-left',
          'is-bottom-center',
          'is-bottom-right'
        ].includes(val);
      }
    },
    /**
     * Delay the visibility when hovering.
     */
    delay: {
      type: [Boolean, Number],
      default: false
    },
    variant: {
      type: String,
      default: 'is-dark',
      validator: (val) => {
        return ['is-dark', 'is-light'].includes(val);
      }
    },
    tooltipWidth: {
      type: String,
      default: '250px'
    }
  },
  emits: ['showTooltip', 'hideTooltip', 'beforeShowTooltip'],
  data () {
    return {
      tooltipPositionRect: {
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        height: 'fit-content'
      },
      tooltipArrowLocation: 'is-bottom-left',
      tooltipVisible: false,
      hovered: false
    };
  },
  computed: {
    tooltipPosition: {
      get () {
        return this.tooltipPositionRect;
      },
      set (val) {
        this.tooltipPositionRect = val;
      }
    },
    hasPositionOverride () {
      return this.position !== 'is-dynamic';
    }
  },
  methods: {
    calculateTooltipLocation (tooltipRect, triggerRect, fits) {
      if (fits.top && fits.left && !fits.right && !fits.bottom) {
        this.tooltipPosition.top = '-' + (tooltipRect.height + 8) + 'px';
        this.tooltipPosition.left =
          '-' + (tooltipRect.width - (15 + 9) - triggerRect.width / 2) + 'px';
        this.tooltipArrowLocation = 'is-bottom-right';
      }
      if (fits.top && fits.right && !fits.left && !fits.bottom) {
        this.tooltipPosition.top = '-' + (tooltipRect.height + 8) + 'px';
        this.tooltipPosition.left = '' + (triggerRect.width / 2 - 18) + 'px';
        this.tooltipArrowLocation = 'is-bottom-left';
      }
      if (fits.top && !fits.left && !fits.right && !fits.bottom) {
        this.tooltipPosition.top = '-' + (tooltipRect.height + triggerRect.height / 2) + 'px';
        this.tooltipPosition.left = '-' + (tooltipRect.width / 2 - triggerRect.width / 2 / 2) + 'px';
        this.tooltipArrowLocation = 'is-bottom-center';
      }
      if (fits.left && !fits.top && !fits.bottom && !fits.right) {
        this.tooltipPosition.top = '-' + (tooltipRect.height / 2 - triggerRect.height / 2) + 'px';
        this.tooltipPosition.left = '-' + (tooltipRect.width + 8) + 'px';
        this.tooltipArrowLocation = 'is-center-right';
      }
      if (fits.right && !fits.top && !fits.bottom && !fits.left) {
        this.tooltipPosition.top = '-' + (tooltipRect.height / 2 - triggerRect.height / 2) + 'px';
        this.tooltipPosition.left = '' + (triggerRect.width + 8) + 'px';
        this.tooltipArrowLocation = 'is-center-left';
      }
      if (fits.bottom && fits.left && !fits.right && !fits.top) {
        this.tooltipPosition.top = '' + (triggerRect.height + 8) + 'px';
        this.tooltipPosition.left =
          '-' + (tooltipRect.width - triggerRect.width / 2 - (15 + 9)) + 'px';
        this.tooltipArrowLocation = 'is-top-right';
      }
      if (fits.bottom && fits.right && !fits.left && !fits.top) {
        this.tooltipPosition.top = '' + (triggerRect.height + 8) + 'px';
        this.tooltipPosition.left = '-' + (15 - triggerRect.width / 2 / 2) + 'px';
        this.tooltipArrowLocation = 'is-top-left';
      }
      if (fits.bottom && !fits.left && !fits.right && !fits.top) {
        this.tooltipPosition.top = '' + (triggerRect.height + 8) + 'px';
        this.tooltipPosition.left = '-' + (tooltipRect.width / 2 - triggerRect.width / 2 / 2) + 'px';
        this.tooltipArrowLocation = 'is-top-center';
      }
    },
    async triggerTooltip () {
      const hoverTime = Date.now();
      this.hovered = hoverTime;
      if (this.delay !== false) {
        await this.sleep(this.delay);
        if (this.hovered !== hoverTime) return;
      }
      this.tooltipVisible = true;
      this.$emit('beforeShowTooltip', this);

      this.$nextTick(() => {
        const triggerRect = this.$refs.trigger?.getBoundingClientRect();
        const tooltipRect = this.$refs.tooltip?.getBoundingClientRect();

        if (triggerRect === undefined) {
          console.error('triggerRect undefined', this.$refs.trigger);
          return;
        }
        if (tooltipRect === undefined) {
          console.error('tooltipRect undefined', this.$refs.tooltip);
          return;
        }

        const fits = {
          top: false,
          left: false,
          right: false,
          bottom: false
        };

        if (triggerRect.top - tooltipRect.height > tooltipRect.height) {
          fits.top = true;
        }

        if (triggerRect.bottom - tooltipRect.height < tooltipRect.height) {
          fits.bottom = true;
        }

        if (triggerRect.right < tooltipRect.width) {
          fits.right = true;
        }

        if (triggerRect.left > tooltipRect.width) {
          fits.left = true;
        }

        // If no position override is defined, calculate it.
        if (this.hasPositionOverride) {
          switch (this.position) {
            case 'is-top-left':
              fits.top = true;
              fits.left = true;
              fits.bottom = false;
              fits.right = false;
              break;
            case 'is-top-center':
              fits.top = true;
              fits.left = false;
              fits.bottom = false;
              fits.right = false;
              break;
            case 'is-top-right':
              fits.top = true;
              fits.left = false;
              fits.bottom = false;
              fits.right = true;
              break;
            case 'is-center-left':
              fits.top = false;
              fits.left = true;
              fits.bottom = false;
              fits.right = false;
              break;
            case 'is-center-right':
              fits.top = false;
              fits.left = false;
              fits.bottom = false;
              fits.right = true;
              break;
            case 'is-bottom-left':
              fits.top = false;
              fits.left = true;
              fits.bottom = true;
              fits.right = false;
              break;
            case 'is-bottom-center':
              fits.top = false;
              fits.left = false;
              fits.bottom = true;
              fits.right = false;
              break;
            case 'is-bottom-right':
              fits.top = false;
              fits.left = false;
              fits.bottom = true;
              fits.right = true;
              break;
            default:
              fits.top = true;
              fits.left = true;
              fits.bottom = false;
              fits.right = false;
              break;
          }
        }

        this.$emit('showTooltip', this);

        return this.calculateTooltipLocation(tooltipRect, triggerRect, fits);
      });
    },
    hideTooltip () {
      this.hovered = false;
      // Reset position
      this.$emit('hideTooltip', this);
      this.tooltipVisible = false;
      this.tooltipPosition.top = 0;
      this.tooltipPosition.left = 0;
    },
    sleep (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
  }
};
</script>

<style scoped lang="scss">
@import '/submodules/vue-framework/src/scss/Colors.scss';

.tooltip-container {
  display: inline-flex;
  position: relative;
}

.tooltip-trigger {
  display: inline-flex;
}

.tooltip-text {
  width: 250px;
  background-color: $darker;
  color: white;
  position: absolute;
  padding: 10px;
  border-radius: 2px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  z-index: 99999;

  p {
    margin: 0;
  }
  &.is-dark {
    background-color: $darker;
    color: $light-text-light;
  }
  &.is-light {
    background-color: $light-text-light;
    color: $darker;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
}

.tooltip-text {
  &.is-top-left {
    &::after {
      top: -18px; /* At the top of the tooltip */
      left: 15px;
      border-color: transparent transparent $darker transparent;
    }
    &.is-light::after {
      border-color: transparent transparent $light-text-light transparent;
    }
  }

  &.is-top-center {
    &::after {
      top: -18px; /* At the top of the tooltip */
      left: 50%;
      border-color: transparent transparent $darker transparent;
    }
    &.is-light::after {
      border-color: transparent transparent $light-text-light transparent;
    }
  }

  &.is-top-right {
    &::after {
      top: -18px; /* At the top of the tooltip */
      right: 15px;
      border-color: transparent transparent $darker transparent;
    }
    &.is-light::after {
      border-color: transparent transparent $light-text-light transparent;
    }
  }

  &.is-center-right {
    &::after {
      top: 0; /* At the top of the tooltip */
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      height: 5px;

      right: -18px;
      border-color: transparent transparent transparent $darker;
    }
    &.is-light::after {
      border-color: transparent transparent transparent $light-background-light;
    }
  }

  &.is-center-left {
    &::after {
      top: 0; /* At the top of the tooltip */
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      height: 5px;

      left: -13px;
      border-color: transparent $darker transparent transparent;
    }
    &.is-light::after {
      border-color: transparent $light-text-light transparent transparent;
    }
  }

  &.is-bottom-left {
    &::after {
      top: 100%; /* At the bottom of the tooltip */
      left: 15px;
      border-color: $darker transparent transparent transparent;
    }
    &.is-light::after {
      border-color: $light-text-light transparent transparent transparent;
    }
  }

  &.is-bottom-center {
    &::after {
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      border-color: $darker transparent transparent transparent;
    }
    &.is-light::after {
      border-color: $light-text-light transparent transparent transparent;
    }
  }

  &.is-bottom-right {
    &::after {
      top: 100%; /* At the bottom of the tooltip */
      right: 15px;
      border-color: $darker transparent transparent transparent;
    }
    &.is-light::after {
      border-color: $light-text-light transparent transparent transparent;
    }
  }
}

.tooltip-text::after {
  content: ' ';
  position: absolute;

  margin-left: -5px;
  border-width: 9px;
  border-style: solid;
}
</style>
